import axios from "./request"
export function getAddressList(body){
  return axios({
      url: "/address/getList",
      method: "get",
      params: body
  })
}
export function GetAddressNameByIds(body){
  return axios({
      url:"/address/GetName",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}
export function getStandardAddressList(body){
  return axios({
      url: "/address/getStandardList",
      method: "get",
      params: body
  })
}
export function position(body){
  return axios({
      url: "/address/position",
      method: "get",
      params: body
  })
}
export function GetQuestionList(body){
  return axios({
      url: "/Standard/GetQuestionList",
      method: "get",
      params: body
  })
}
export function GetAllInPayUserInfo(body){
  return axios({
      url:"allinpay/GetUserInfo",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,            
      params: body
  });
}
export function CheckCode(body){
  return axios({
      url:"/SMS/CheckAuthCode",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,            
      params: body
  });
}
export function SignContract(body){
  return axios({
      url:"/AllinPay/SignContract",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}
export function payPhoneSendMsg(body){
  return axios({
      url:"/AllinPay/SendMsg",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}
export function bindPhone(body){
  return axios({
      url:"/AllinPay/BindPhone",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}
export function authentication(body){
  return axios({
      url:"/AllinPay/Authentication",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}
export function getBankCard(body){
  return axios({
      url:"/AllinPay/GetBankCard",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,            
      params: body
  });
}
export function removeBind(body){
  return axios({
      url:"/AllinPay/UnBindBankCard",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}
export function PayByBackSMS(body){
  return axios({
      url:"/AllinPay/PayByBackSMS",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}
export function DepositApply(body){
  return axios({
      url:"/AllinPay/DepositApply",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}
export function WithdrawApply(body){
  return axios({
      url:"/AllinPay/WithdrawApply",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}
export function SetCompanyInfo(body){
  return axios({
      url:"/AllinPay/SetCompanyInfo",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}
export function confirmBindBankCard(body){
  return axios({
      url:"/AllinPay/ConfirmBindBankCard",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}
export function applyBindBankCard(body){
  return axios({
      url:"/AllinPay/ApplyBindBankCard",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      data: body
  });
}
export function getAccountBalance(body){
  return axios({
      url:"/AllinPay/GetAccountBalance",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,            
      params: body
  });
}
export function commissionList(body){
  return axios({
      url: "/Commission/GetPageList",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,   
      params: body
  })
}
export function userBillList(body){
  return axios({
      url: "/Bill/GetPageList",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,   
      params: body
  })
}