<template>
  <div id="app" >
     <van-nav-bar
        left-text="返回"
        left-arrow
        title="实名认证"
        @click-left="back"/>
      <van-action-sheet
        v-model="show"
        :actions="actions"
        cancel-text="取消"
        @select='onActionSelect'
        close-on-click-action
        @cancel="show=false"
      />
    <van-form @submit="onSubmit">
      <van-cell-group>
        <label for="">请确认您的身份信息：</label>
      </van-cell-group>
      <div style="margin: 20px 0px">
        <van-cell-group>
          <van-field
            v-model="from.name"
            label="姓名"
            maxlength='10'
            :rules="[{ validator:NameValid, message: '您的姓名格式错误' }]"
            placeholder="请输入您的姓名"/>
          <van-field
            v-model="from.IdentityTypeName"
            label="证件类型"
            maxlength='10'
            readonly
            @click="show=true"
            placeholder="请选择您的证件类型"/>
          <van-field
            v-model="from.authCode"
            label="身份证号码"
            :rules="[{ validator:IdentityCodeValid, message: '身份证号码格式错误' }]"
            placeholder="请输入您的身份证号码"/>
        </van-cell-group>
      </div>
      <van-cell-group>
        <van-button round size="large" icon="user-o" type="info" block>身份验证</van-button>
      </van-cell-group>
    </van-form>
  </div>
</template>

<script>
import { Button, Cell, CellGroup, Field, Form,NavBar,ActionSheet } from "vant";
import {allinpayUser} from '../../store/allinpay'
import {authentication} from '../../api/common'
export default {
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Form.name]: Form,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
  },
  data(){
      return {
          indexUrl:'',
          show:false,
          actions:[],
          from:{
              name:'',
              authCode:'',
              IdentityType:'',
              IdentityTypeName:''
          },
          sysUser:'',
      };
  },
  methods:{
    back(){
      if(this.scrapUserId){
          this.$router.go(-1);
      }else{
          this.$router.replace(this.indexUrl);
      }
    },
    onActionSelect(val){
      this.from.IdentityType = val.id;
      this.from.IdentityTypeName = val.name;
      console.log(val);
    },
    onSubmit(values){
      var body;
      if(this.scrapUserId){
        body = {
          userId:this.scrapUserId,
          userSource:2,
          Name:this.from.name,
          IdentityNo:this.from.authCode,
          IdentityType:this.from.IdentityType
        };
      }else{
        var user = allinpayUser.get();
        body = {
          userId:user.userId,
          userSource:user.userSource,
          Name:this.from.name,
          IdentityNo:this.from.authCode,
          IdentityType:this.from.IdentityType
        };
      }
      
      this.$toast.loading({duration: 0,forbidClick: true,message: '提交中'});
      authentication(body).then(res=>{
        this.$toast.clear();
        this.$toast({type:res.status?'success':'fail',message:res.message});
        if(this.scrapUserId){
          if(res.status){
            this.$router.go(-1);
          }
        }else{
          (res.status||res.message == '已实名认证')&&this.$router.push('/bank/wallet');
        }
      }).catch(()=>{
        this.$toast.clear();
      })
      console.log('submit', values);
    },
    IdentityCodeValid(code) { 
        var city={11:"北京",12:"天津",13:"河北",14:"山西",15:"内蒙古",21:"辽宁",22:"吉林",23:"黑龙江 ",31:"上海",32:"江苏",33:"浙江",34:"安徽",35:"福建",36:"江西",37:"山东",41:"河南",42:"湖北 ",43:"湖南",44:"广东",45:"广西",46:"海南",50:"重庆",51:"四川",52:"贵州",53:"云南",54:"西藏 ",61:"陕西",62:"甘肃",63:"青海",64:"宁夏",65:"新疆",71:"台湾",81:"香港",82:"澳门",91:"国外 "};
        var pass= true;
        if(!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code)){
            pass = false;
        }else if(!city[code.substr(0,2)]){
            pass = false;
        }else{
            //18位身份证需要验证最后一位校验位
            if(code.length == 18){
                code = code.split('');
                //∑(ai×Wi)(mod 11)
                //加权因子
                var factor = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ];
                //校验位
                var parity = [ 1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2 ];
                var sum = 0;
                var ai = 0;
                var wi = 0;
                for (var i = 0; i < 17; i++)
                {
                    ai = code[i];
                    wi = factor[i];
                    sum += ai * wi;
                }
                if(parity[sum % 11] != code[17]){
                    pass =false;
                }
            }
        }
        return pass;
    },
    NameValid(val){
        if(!val){
            return false;
        }else if(val.length>10){
            return false;
        }else{
            return true;
        }
    }
  },
  mounted(){
  },
  created(){
    this.scrapUserId = this.$route.query.scrapUserId;
    for(var i in  this.$enum.IdentityTypeEnum){
      this.actions.push({name:this.$enum.IdentityTypeEnum[i],id:i});
    }
    this.sysUser = allinpayUser.get();
    this.from.name = this.sysUser.realName;
    // if(!this.scrapUserId){
    //   this.sysUser = allinpayUser.get();
    //   this.from.name = this.sysUser.realName;
    //   if(this.sysUser.userSource ==1 ){
    //       this.indexUrl = "/mindex"
    //   }else{
    //       this.indexUrl = "/personalIndex"
    //   }
    // }
  }
};
</script>

<style>
</style>